// eslint-disable-next-line import/prefer-default-export
export const listApplications = /* GraphQL */ `
    query ListApplications(
        $id: ID
        $filter: ModelApplicationFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listApplications(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                student {
                    id
                    name {
                        first
                        last
                    }
                    school {
                        id
                        name {
                            legal
                        }
                    }
                }
                ranking {
                    local
                    score
                }
                applicationFestivalId
                applicationFormId
                applicationInstrumentId
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

export const getApplication = /* GraphQL */ `
    query GetApplication($id: ID!) {
        getApplication(id: $id) {
            id
            student {
                id
                name {
                    first
                    last
                }
                school {
                    id
                    name {
                        legal
                    }
                }
            }
            ranking {
                local
                score
            }
            applicationFestivalId
            applicationFormId
            applicationInstrumentId
            createdAt
            updatedAt
        }
    }
`;

export const getApplicationDetails = /* GraphQL */ `
    query GetApplication(
        $id: ID!
    ) {
        getApplication(id: $id) {
            id
            form {
                id
                name
                type
            }
            festival {
                id
                name
            }
            instrument {
                id
                name
            }
            student {
                id
                grade
                dob
                address {
                    line1
                    line2
                    city
                    state
                    zip
                }
                phone {
                    type
                    number
                    ext
                }
                email {
                    type
                    address
                }
            }
            questions {
                lastYear {
                    attended
                    group
                    part
                    chair
                }
                moreThanOne {
                    response
                    instruments
                }
                organizations {
                    school
                    other
                }
                experience {
                    instrument {
                        years
                        doubles
                    }
                    vocalJazzAccompaniment
                    jazzEnsemble
                    orchestral
                }
                previousSoloRating {
                    ninth {
                        grade
                        level
                    }
                    tenth {
                        grade
                        level
                    }
                }
            }
            grading {
                tone
                rhythmicSense
                leadership
                maturity
                articulation
                intonation
                citizenship
                diction
                flexibility
                generalMusicianship
                attitude
                technique
                sightReading
                responsibility
            }
            recommendation
            comments
        }
    }
`;

export const updateApplication = /* GraphQL */ `
    mutation UpdateApplication(
        $input: UpdateApplicationInput!
        $condition: ModelApplicationConditionInput
    ) {
        updateApplication(input: $input, condition: $condition) {
            id
        }
    }
`;

export const onCreateApplication = /* GraphQL */ `
    subscription OnCreateApplication {
        onCreateApplication {
            id
        }
    }
`;

export const onUpdateApplication = /* GraphQL */ `
    subscription OnUpdateApplication {
        onUpdateApplication {
            id
            ranking {
                local
                score
            }
        }
    }
`;

export const listFestivals = /* GraphQL */ `
    query ListFestivals(
        $id: ID
        $filter: ModelFestivalFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listFestivals(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name
                site
                zone {
                    name
                }
                state {
                    enabled
                }
                festivalZoneId
            }
            nextToken
        }
    }
`;

export const listForms = /* GraphQL */ `
    query ListForms(
        $id: ID
        $filter: ModelFormFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listForms(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name
                state {
                    enabled
                }
            }
            nextToken
        }
    }
`;

export const listInstruments = /* GraphQL */ `
    query ListInstruments(
        $id: ID
        $filter: ModelInstrumentFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listInstruments(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name
                state {
                    enabled
                }
            }
            nextToken
        }
    }
`;

export const listSchools = /* GraphQL */ `
    query ListSchools(
        $id: ID
        $filter: ModelSchoolFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listSchools(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name {
                    legal
                    popular
                }
            }
            nextToken
        }
    }
`;
