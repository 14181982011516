<template>
  <page-layout ref="layout" @refresh="refresh">
    <template #breadcrumbs>
      <b-breadcrumb-item :text="`All-State - ${$store.state.settings.app.current.title}`" />
      <b-breadcrumb-item text="Scores" active/>
    </template>

    <template #actions="{ state }">
      <template v-if="isCreatedAtInCurrentYear(new Date().toISOString()) || $can('manage', 'all-state-scores')">
        <can do="update" on="all-state-scores">
          <b-button :disabled="!save.changes.length" variant="link" size="sm" @click="saveChanges(state)">
            <font-awesome-icon icon="fa-solid fa-cloud-arrow-up" class="mr-50"/> Update
          </b-button>
        </can>
      </template>
    </template>

    <template #dropdown-options>
      <b-dropdown-item @click="ui.layout.showLeftColumn = !ui.layout.showLeftColumn">
        <feather-icon :icon="ui.layout.showLeftColumn ? 'Minimize2Icon' : 'Maximize2Icon'"/>
        <span class="align-middle ml-50">{{ ui.layout.showLeftColumn ? 'Hide' : 'Show' }} Sidebar</span>
      </b-dropdown-item>
      <b-dropdown-divider/>
      <b-dropdown-item :disabled="save.processing" @click="refresh">
        <feather-icon icon="RotateCwIcon"/>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
    </template>

    <template #loading="{ state }">
      <overlay-loading :items="[
          { state: options.festivals.loading, desc: 'Loading Festivals'},
          { state: options.forms.loading, desc: 'Loading Forms'},
          { state: options.instruments.loading, desc: 'Loading Instruments'},
          { state: state.loading, desc: 'Rendering Template'},
          ]">
      </overlay-loading>
    </template>

    <template #content="{ state }">
      <template>
        <b-row>
          <!-- Left Col -->
          <b-col v-if="ui.layout.showLeftColumn" id="table-filters" class="d-print-none col-12 col-xxl-3">

            <!-- Filters -->
            <b-card-actions title="Filters" action-collapse>
              <b-row>
                <b-col class="col-xxl-12">
                  <b-form-group label="Festival">
                    <v-select id="festival-input"
                              v-model="table.filter.festival" label="name"
                              :options="options.festivals.items"
                              :loading="options.festivals.loading"
                              :reduce="val => val.id"
                              :select-on-tab="true"
                              :filter="filterFestival"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              class="w-100">
                      <template #option="{ id, name, site }">
                        <span class="d-block">Festival {{ name }} <small v-if="settings.showFilterCounts">({{ filterFestivalCount(id) }})</small></span>
                        <small>{{ site }}</small>
                      </template>
                      <template #selected-option="{ id, name, site }">
                        <span class="d-block">Festival {{ name }} - {{ site }}</span>
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>
                <b-col class="col-xxl-12">
                  <b-form-group label="Instrument">
                    <v-select id="instrument-input"
                              v-model="table.filter.instrument"
                              label="name"
                              :options="options.instruments.items"
                              :loading="options.instruments.loading"
                              :reduce="val => val.id"
                              :select-on-tab="true"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              class="w-100">
                      <template #option="{ id, name }">
                        {{ name }} <small v-if="settings.showFilterCounts">({{ filterInstrumentCount(id) }})</small>
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-button v-if="table.filter.student" block variant="outline-danger" class="mt-2" @click="table.filter.student = null">Remove Student Filter</b-button>
            </b-card-actions>

            <!-- Options -->
            <b-card-actions title="Options" action-collapse collapsed>
              <b-row class="pb-1">
                <b-col>
                  <label>Show Filter Counts:</label>
                </b-col>
                <b-col cols="auto">
                  <b-form-checkbox v-model="settings.showFilterCounts" switch @change="onShowFilterCounts"/>
                </b-col>
              </b-row>
              <b-row class="pb-1">
                <b-col>
                  <label>Hide Scored Applications:</label>
                </b-col>
                <b-col cols="auto">
                  <b-form-checkbox v-model="settings.hideScored" switch @change="toggleHideScored" />
                </b-col>
              </b-row>
              <b-row class="pb-1">
                <b-col>
                  <label>Score As Combo:</label>
                </b-col>
                <b-col cols="auto">
                  <b-form-checkbox v-model="settings.scoreAsCombo" switch @change="onScoreAsCombo"/>
                </b-col>
              </b-row>
            </b-card-actions>

            <b-alert show variant="primary" class="d-print-none">
              <b-list-group>
                <!-- Cognito User -->
                <b-list-group-item>
                  <div class="d-flex justify-content-between align-items-center">
                    Pending Changes ({{ updatableValues.length }})
                    <div class="d-flex align-items-center">
                      <b-button size="sm" variant="link"
                                :aria-expanded="save.details.changes ? 'true' : 'false'"
                                aria-controls="save-details-changes"
                                class="px-0"
                                @click="save.details.changes = !save.details.changes">
                        {{ save.details.changes ? 'Hide' : 'Show' }} Details
                      </b-button>
                    </div>
                  </div>
                  <!-- Element to collapse -->
                  <b-overlay :show="save.processing" variant="white" :opacity="1">
                    <vue-perfect-scrollbar :settings="{ maxScrollbarLength: 150,wheelPropagation: false }" class="ps-changes">
                      <b-collapse id="save-details-changes" v-model="save.details.changes">
                        <b-list-group v-if="updatableValues.length" flush class="pt-50">
                          <b-list-group-item v-for="(item, index) in updatableValues" :key="index" class="px-0 py-25">
                            <div class="d-flex justify-content-between align-items-center">
                              <div>
                                <small class="d-block">Application: {{ item.id }}</small>
                                <small v-for="(change, i2) in item.changes" :key="index + '-' + i2" class="d-block">
                                  Update <span class="text-capitalize">{{ change.type }}</span> from {{ change.from }} to {{ change.to }}
                                </small>
                              </div>
                              <b-button variant="link" size="sm" class="btn-icon" @click="table.search = item.id">
                                <b-icon-search />
                              </b-button>
                            </div>
                          </b-list-group-item>
                        </b-list-group>
                        <small v-else class="pt-50">Nothings been updated yet.</small>
                      </b-collapse>
                    </vue-perfect-scrollbar>
                  </b-overlay>
                </b-list-group-item>
              </b-list-group>
            </b-alert>

            <template v-if="state.debug">
              <debug title="Captured Changes">
                {{ save.changes }}
              </debug>

              <debug title="Computed Save Values">
                {{ updatableValues }}
              </debug>
            </template>
          </b-col>

          <!-- Right Col -->
          <b-col>
            <b-overlay :show="save.processing || save.complete" no-center fixed>
              <template #overlay>
                <b-alert show variant="primary" class="d-print-none">
                  <b-list-group>
                    <b-list-group-item>
                      <div class="d-flex justify-content-between align-items-center">
                        Processed Changes ({{ save.computedChanges.length }})
                        <div class="d-flex align-items-center">
                          <b-button size="sm" variant="link"
                                    :aria-expanded="save.details.processed ? 'true' : 'false'"
                                    aria-controls="save-details-processed"
                                    class="mr-1"
                                    @click="save.details.processed = !save.details.processed">
                            {{ save.details.processed ? 'Hide' : 'Show' }} Details
                          </b-button>
                          <b-spinner v-if="save.processing" small :class="getSaveClass()"></b-spinner>
                          <b-icon v-else :icon="getSaveIcon(save)" :variant="getSaveVariant(save)" :class="getSaveClass()"/>
                        </div>
                      </div>
                      <div v-if="save.processing" class="my-1">
                        <small class="mb-25">{{ save.status }}</small>
                        <b-progress>
                          <b-progress-bar :value="save.progress" :max="save.computedChanges.length"></b-progress-bar>
                        </b-progress>
                      </div>


                      <!-- Element to collapse -->
                      <vue-perfect-scrollbar ref="processed-scrollbar" :settings="{ maxScrollbarLength: 150,wheelPropagation: false }" class="ps-processed">
                        <b-collapse id="save-details-processed" v-model="save.details.processed">
                          <b-list-group v-if="save.computedChanges.length" flush class="pt-50">
                            <b-list-group-item v-for="(item, index) in save.computedChanges" :key="index" class="px-0 py-25">
                              <div class="d-flex justify-content-between align-items-center">
                                <div>
                                  <small class="d-block">Application: {{ item.id }}</small>
                                  <small v-for="(change, i2) in item.changes" :key="index + '-' + i2" class="d-block">
                                    Update <span class="text-capitalize">{{ change.type }}</span> from {{ change.from }} to {{ change.to }}
                                  </small>
                                  <small class="d-block">
                                    Updated <span class="text-capitalize">{{ item.updatedAt | date }}</span>
                                  </small>
                                  <small v-if="item.error" class="d-block text-danger">Error: {{ item.error }}</small>
                                </div>
                                <b-spinner v-if="item.saving" small></b-spinner>
                                <b-icon v-else :icon="item.icon" :variant="item.variant"/>
                              </div>
                            </b-list-group-item>
                          </b-list-group>
                          <small v-else class="pt-50">Nothings been updated yet.</small>
                        </b-collapse>
                      </vue-perfect-scrollbar>
                    </b-list-group-item>
                  </b-list-group>
                  <div class="d-flex justify-content-end">
                    <b-button v-if="save.complete" block class="mt-50 shadow-none" variant="outline-white" @click="save.complete = false">Back to Table</b-button>
                  </div>
                </b-alert>
              </template>
              <template #default>
                <b-card v-if="!save.processing && !save.complete" no-body class="table-card" header-class="d-block d-print-none" footer-class="py-1 d-print-none">
                  <template #header>
                    <b-row id="table-header">
                      <b-col align-self="center" cols="auto">
                        <!-- Columns -->
                        <b-dropdown title="Columns" lazy no-flip
                                    variant="transparent" size="sm"
                                    boundary="window" :popper-opts="{ positionFixed: true }">
                          <template #button-content>
                            <b-icon icon="layout-three-columns" aria-label="Columns"></b-icon>
                          </template>
                          <b-dropdown-header id="dropdown-header-label">Columns</b-dropdown-header>
                          <b-dropdown-divider/>
                          <b-dropdown-form v-for="field in table.fields.filter(f => f.label)" :key="field.key">
                            <b-form-checkbox v-model="field.visible">
                              {{ field.label }}
                            </b-form-checkbox>
                          </b-dropdown-form>
                        </b-dropdown>


                        <!-- Sort ASC/DESC -->
                        <b-dropdown title="Sorting" lazy split no-flip
                                    variant="transparent" size="sm"
                                    toggle-class="border-0" split-class="border-0" menu-class="dropdown-menu-lg"
                                    boundary="window" :popper-opts="{ positionFixed: true }" @click="onSortOrder">
                          <template #button-content>
                            <b-icon :icon="table.sorting.desc ? 'sort-alpha-down-alt' : 'sort-alpha-down'" aria-label="Sort"></b-icon>
                          </template>

                          <!-- Sort Fields -->
                          <b-dropdown-group v-b-toggle.collapse-sort-fields
                                            header-classes="text-muted d-flex justify-content-between"
                                            role="tab">
                            <template #header>
                              Fields
                              <feather-icon icon="ChevronDownIcon" size="16" :class="{ rotate: null }"/>
                            </template>
                            <template #default>
                              <b-collapse id="collapse-sort-fields" accordion="sort-accordion" role="tabpanel" :visible="table.sorting.comparator === null">
                                <b-dropdown-form>
                                  <b-form-radio v-model="table.sorting.by" :value="'id'" @change="onSortBy">
                                    ID
                                  </b-form-radio>
                                </b-dropdown-form>
                                <b-dropdown-form v-for="field in table.sorting.fields" :key="field.key">
                                  <b-form-radio v-model="table.sorting.by" :value="field.key" @change="onSortBy">
                                    {{ field.text }}
                                  </b-form-radio>
                                </b-dropdown-form>
                              </b-collapse>
                            </template>
                          </b-dropdown-group>

                          <!-- Sort Comparators -->
                          <template>
                            <b-dropdown-divider/>
                            <b-dropdown-group v-b-toggle.collapse-sort-comparators
                                              header-classes="text-muted d-flex justify-content-between"
                                              role="tab">
                              <template #header>
                                Comparator
                                <feather-icon icon="ChevronDownIcon" size="16" :class="{ rotate: null }"/>
                              </template>
                              <template #default>
                                <b-collapse id="collapse-sort-comparators" accordion="sort-accordion" role="tabpanel" :visible="table.sorting.comparator !== null">
                                  <b-dropdown-form v-for="item in table.sorting.comparators" :key="item.key">
                                    <b-form-radio v-model="table.sorting.comparator"
                                                  :value="item.comparator"
                                                  @change="onSortCompare(item)">
                                      {{ item.text }}
                                    </b-form-radio>
                                  </b-dropdown-form>
                                </b-collapse>
                              </template>

                            </b-dropdown-group>

                          </template>


                        </b-dropdown>

                        <!-- Paging -->
                        <b-dropdown title="Paging" lazy no-flip no-caret
                                    variant="transparent" size="sm"
                                    toggle-class="text-decoration-none px-1"
                                    boundary="window" :popper-opts="{ positionFixed: true }">
                          <template #button-content>
                            <b-icon icon="collection" class="mr-0"/><span class="sr-only">Page Size</span> <small class="align-start"> - {{ table.paging.size === 0 ? 'All' : table.paging.size }}</small>
                          </template>
                          <b-dropdown-header id="dropdown-header-label">Page Size</b-dropdown-header>
                          <b-dropdown-divider/>
                          <b-dropdown-item-button v-for="size in table.paging.options.sizes"
                                                  :key="size" button-class="w-100"
                                                  :active="size === table.paging.size"
                                                  active-class="bg-light-primary" @click="onPageSize(size)">
                            {{ size === 0 ? 'All' : size }}
                          </b-dropdown-item-button>
                          <!--        <b-dropdown-text text-class="text-xs" variant="danger">
                                    <strong>Changing the page size will clear all paged data, and go back to page 1</strong>
                                  </b-dropdown-text>-->
                        </b-dropdown>
                      </b-col>
                      <b-col align-self="center">
                        <b-input-group class="input-group-merge pr-1">
                          <b-form-input
                              id="search-apps"
                              v-model="table.search"
                              :debounce="250"
                              autocomplete="off"
                              size="md"
                              placeholder="Search Applications"
                          />
                          <b-input-group-append v-if="table.search" is-text>
                            <feather-icon icon="XIcon" class="text-muted" @click="table.search = ''" />
                          </b-input-group-append>
                          <b-input-group-append is-text>
                            <feather-icon icon="SearchIcon" class="text-muted" />
                          </b-input-group-append>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </template>
                  <template #default>
                    <validation-observer ref="table-observer">
                      <b-table class="position-relative mb-0 overflow-visible"
                               table-class="table-flush"
                               thead-class="thead-light"
                               thead-tr-class="border-0"
                               tbody-class="border-0"
                               tbody-tr-class="tbody-tr-border"
                               responsive show-empty
                               :busy="table.busy"
                               :items="filteredApplications"
                               :fields="visibleFields"
                               :per-page="table.paging.size"
                               :current-page="table.paging.page"
                               :sort-by.sync="table.sorting.by"
                               :sort-desc.sync="table.sorting.desc"
                               :sort-compare.sync="table.sorting.comparator"
                               @sort-changed="onSort">

                        <template #table-busy>
                          <overlay-loading :items="[
                            { state: table.busy, desc: 'Loading Applications', loaded: table.paging.total },
                          ]"/>
                        </template>

                        <template #row-details="row">
													<b-overlay :show="!row.item.hasDetails" opacity="1" @shown="getApplicationDetails(row.item)">
														<b-card no-body header-class="pb-0" class="mb-0 d-print-none">
															<template v-if="row.item.hasDetails">
																<b-tabs card nav-wrapper-class="pt-1 pb-0 px-3" nav-class="flex-grow-1" active-tab-class="px-3">
																	<b-tab title="Details">
																		<application-details :current-user="user"
																												 :festival.sync="row.item.festival"
																												 :form.sync="row.item.form"
																												 :instrument.sync="row.item.instrument"
																												 :editable="row.item.editable" :show-heading="false"/>
																	</b-tab>
																	<b-tab title="Student">
																		<application-student :current-user="user"
																												 :student.sync="row.item.student"
																												 :create-student="false"
																												 :existing-students-options="{ show: false, clearable: false }"
																												 :editable="row.item.editable" :show-heading="false"/>
																	</b-tab>
																	<b-tab title="Questions">
																		<application-questions :form="row.item.form"
																													 :questions.sync="row.item.questions"
																													 :editable="row.item.editable" :show-heading="false"/>
																	</b-tab>
																	<b-tab title="Grading">
																		<application-grading :form="row.item.form"
																												 :student="row.item.student"
																												 :grading.sync="row.item.grading"
																												 :recommendation.sync="row.item.recommendation"
																												 :comments.sync="row.item.comments"
																												 :editable="row.item.editable" :show-heading="false"/>
																	</b-tab>

																	<template #tabs-end>
																		<b-nav-item :active="row.item.editable" class="ml-auto" role="presentation" @click.prevent="row.item.editable = !row.item.editable">
																			<font-awesome-icon icon="edit" class="text-primary"/>
																			<span>{{ row.item.editable ? 'Editing' : 'Edit' }}</span>
                                    </b-nav-item>
																		<b-nav-item v-if="row.item.editable" role="presentation" @click.prevent="updateApplicationDetails(row.item)">
																			<font-awesome-icon icon="cloud-arrow-up" class="text-primary"/>
                                      <span>Update</span>
																		</b-nav-item>
																	</template>
																</b-tabs>
															</template>
															<template v-else>
																<b-alert show variant="light">Loading</b-alert>
															</template>
														</b-card>
													</b-overlay>
                        </template>

                        <template #cell(student.name)="row">
                          <b-media vertical-align="center" no-body>
                            <b-media-aside class="my-auto">
                              <b-avatar button variant="primary" size="2.5em"
                                        badge-variant="primary" @click="row.toggleDetails">
                                <font-awesome-icon icon="fas fa-graduation-cap"></font-awesome-icon>
                                <template #badge>
                                  <b-icon :icon="row.item._showDetails === true ? 'chevron-up' : 'chevron-down'" />
                                </template>
                              </b-avatar>
                            </b-media-aside>
                            <b-media-body class="align-self-center">
                              <h6 class="mb-0">{{ row.item.student.name.first }}  {{ row.item.student.name.last }}</h6>
                              <small v-if="row.item.student.school && row.item.student.school.name">{{ row.item.student.school.name.legal }}</small>
                              <small v-else class="text-danger">No School</small>
                            </b-media-body>
                          </b-media>
                        </template>

                        <template #cell(festival)="row">
                          <div v-if="row.item.applicationFestivalId">
                            {{ getFestivalName(row.item.applicationFestivalId) }}
                          </div>
                          <div v-else class="text-danger">No Festival</div>
                        </template>

                        <template #cell(instrument)="row">
                          <div v-if="row.item.applicationInstrumentId">
                            {{ getInstrumentName(row.item.applicationInstrumentId) }}
                          </div>
                          <div v-else class="text-danger">No Instrument</div>
                        </template>

                        <template #cell(ranking.local)="data">
                          <validation-provider v-slot="validationContext" :ref="`${data.item.id}-local-provider`" :vid="`${data.item.id}-local-rank-provider`" name="Local Rank" :rules="rules.local">
                            <b-input-group :class="getInputGroupClass(validationContext)">
                              <b-form-input :id="`${data.item.id}-local-input`" :ref="`${data.item.id}-local-input`"
                                            v-model="data.item.ranking.local"
                                            number type="number"
                                            placeholder="Local Rank"
                                            :debounce="250"
                                            :disabled="!$can('update', 'all-state-scores', 'local-rank')"
                                            :tabindex="data.item.tabIndex.local"
                                            :state="getValidationState(validationContext)"
                                            @update="onChange('local', $event, rules.local, data.item)"
                                            @focus="onFocus"
                                            @blur="onBlur('local', data.item)"/>
                              <b-input-group-append v-if="getValidationState(validationContext) !== null">
                                <b-input-group-text>
                                  <font-awesome-icon icon="fa-solid fa-rotate-left" @click="undoToInitialItem('local', data.item)"/>
                                </b-input-group-text>
                              </b-input-group-append>
                            </b-input-group>
                          </validation-provider>
                        </template>

                        <template #cell(ranking.score)="data">
                          <validation-provider v-slot="validationContext" :ref="`${data.item.id}-score-provider`" :vid="`${data.item.id}-score-provider`" name="Score" :rules="rules.score">
                            <b-form-group v-if="settings.scoreAsCombo" :state="getValidationState(validationContext)" class="mb-0">
                              <b-input-group :class="getInputGroupClass(validationContext)">
                                <v-select :id="`${data.item.id}-score-select`" :ref="`${data.item.id}-score-select`"
                                          v-model.number="data.item.ranking.score"
                                          :options="options.scores"
                                          taggable
                                          :select-on-tab="true"
                                          placeholder="0"
                                          :tabindex="data.item.tabIndex.score"
                                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                          :disabled="!$can('update', 'all-state-scores', 'score')"
                                          append-to-body :calculate-position="withPopper"
                                          @input="onChange('score', $event, rules.score, data.item)"
                                          @search:blur="onBlur('score', data.item)">
                                          <!--append-to-body :calculate-position="withPopper"-->
                                </v-select>
                                <b-input-group-append v-if="getValidationState(validationContext) !== null" >
                                  <b-input-group-text>
                                    <font-awesome-icon icon="fa-solid fa-rotate-left" @click="undoToInitialItem('score', data.item)"/>
                                  </b-input-group-text>
                                </b-input-group-append>
                              </b-input-group>
                            </b-form-group>

                            <!-- append-to-body :calculate-position="withPopper"-->
                            <b-input-group v-else :class="getInputGroupClass(validationContext)">
                              <b-input
                                  v-model="data.item.ranking.score" number
                                  type="number" placeholder="Score"
                                  :debounce="250"
                                  :formatter="formatInput"
                                  :tabindex="data.item.tabIndex.score"
                                  :disabled="!$can('update', 'all-state-scores', 'score')"
                                  :state="getValidationState(validationContext)"
                                  @update="onChange('score', $event, rules.score, data.item)"
                                  @blur="onBlur('score', data.item)"/>
                              <b-input-group-append v-if="getValidationState(validationContext) !== null">
                                <b-input-group-text>
                                  <font-awesome-icon icon="fa-solid fa-rotate-left" @click="undoToInitialItem('score', data.item)"/>
                                </b-input-group-text>
                              </b-input-group-append>
                            </b-input-group>
                          </validation-provider>
                        </template>

                        <template #cell(createdAt)="data">
                          <last-modified :date="data.item.createdAt" no-text />
                        </template>

                        <!-- Column: State - Enabled -->

                        <template #cell(updatedAt)="data">
                          <last-modified :date="data.item.updatedAt" no-text />
                        </template>

                        <template #cell(row-options)="data">
                          <table-row-options :index="data.index" toggle-class="px-50">
                            <b-dropdown-item :to="{ name: 'all-state-application', params: { id: data.item.id } }"
                                             class="table-row-option-view">
                              <feather-icon icon="FileTextIcon" />
                              <span class="align-middle ml-50">View Application</span>
                            </b-dropdown-item>
                            <b-dropdown-item :to="{ name: 'all-state-student', params: { id: data.item.student.id } }"
                                             class="table-row-option-view">
                              <feather-icon icon="FileTextIcon" />
                              <span class="align-middle ml-50">View Student</span>
                            </b-dropdown-item>
                            <b-dropdown-divider/>
                            <b-dropdown-item-button class="table-row-option-details" @click="data.toggleDetails">
                              <feather-icon :icon="`Toggle${data.item._showDetails ? 'Left': 'Right'}Icon`"/>
                              <span class="align-middle ml-50">Toggle Row Details</span>
                            </b-dropdown-item-button>

                          </table-row-options>
                        </template>
                      </b-table>
                    </validation-observer>
                  </template>
                  <template v-if="!table.busy" #footer>
                    <b-row>
                      <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <div id="table-layout-paging-details">
                          <span v-if="table.items.length > 0" class="text-muted">Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.of }} entries</span>
                          <span v-else class="text-muted">Showing 0 of {{ pagination.of }} entries</span>
                        </div>
                      </b-col>

                      <!-- Pagination -->
                      <b-col class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <!-- :per-page="0" on b-table disables table pagination, however it does not disable the paging on the b-pagination component.
                              In order to trick b-pagination to only show 1 page when :per-page="0" is to make :total-rows toggle it's value. -->
                        <b-pagination id="table-layout-paging-pagination" v-model="table.paging.page"
                                      :total-rows="table.paging.size > 0 ? table.paging.total : 0"
                                      :per-page="table.paging.size"
                                      first-number
                                      last-number
                                      class="mb-0 mt-1 mt-sm-0"
                                      prev-class="prev-item"
                                      next-class="next-item"
                                      @input="onPageChange"
                        >
                          <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18"/>
                          </template>
                          <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18"/>
                          </template>
                        </b-pagination>
                      </b-col>
                    </b-row>
                  </template>
                </b-card>
              </template>
            </b-overlay>
          </b-col>
        </b-row>
      </template>
    </template>

    <template #debug>
      <b-row>
        <b-col>
          <debug title="Table Items" :collapsed="true">
            {{ table.items.slice(0, 10) }}
          </debug>
        </b-col>
        <b-col>
          <debug title="Table Initial Items Values" :collapsed="true">
            {{ table.initialItems.slice(0, 10) }}
          </debug>
        </b-col>
      </b-row>
    </template>

  </page-layout>
</template>

<script>
import _ from 'lodash'
import { validate } from 'vee-validate';
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import PageLayout from '@/components/PageLayout.vue';
import {API, Auth, graphqlOperation} from 'aws-amplify';
import {
  listApplications, getApplication, getApplicationDetails, updateApplication, onCreateApplication, onUpdateApplication,
  listFestivals, listInstruments, listForms, listSchools
} from './queries/scores-table';
import Fuse from 'fuse.js';
import print from '@/mixins/print.mixin';
import notify from '@/mixins/notify.mixin';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import OverlayLoadingText from '@/components/OverlayLoadingText.vue';
import OverlayLoading from '@/components/OverlayLoading.vue';
import cache from '@/mixins/storage.cache.mixin'
import local from '@/mixins/storage.local.mixin'
import TableRowOptions from '@/components/TableRowOptions.vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import ApplicationStudent from '@/views/all-state/application/ApplicationStudent.vue';
import ApplicationDetails from '@/views/all-state/application/ApplicationDetails.vue';
import ApplicationQuestions from '@/views/all-state/application/ApplicationQuestions.vue';
import ApplicationGrading from '@/views/all-state/application/ApplicationGrading.vue';
import {getUser} from '@/views/all-state/selections/selection-table';
import popper from '@/mixins/popper.mixin';
import settingsMixin from '@/mixins/settings.mixin';
import LastModified from '@/components/LastModified.vue';

export default {
  directives: {
    Ripple,
  },
  filters: {
    date(value) {
      return value ? new Intl.DateTimeFormat('en', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }).format(new Date(value)) : null
    }
  },
  components: {
    LastModified,
    ApplicationGrading,
    ApplicationQuestions,
    ApplicationDetails,
    ApplicationStudent,
    TableRowOptions,
    OverlayLoading,
    OverlayLoadingText,
    BCardActions,
    PageLayout,
    vSelect,
    VuePerfectScrollbar
  },
  mixins: [ print, notify, cache, local, popper, settingsMixin ],
  data() {
    return {
      user: null,
      settings: {
        hideScored: false,
        scoreAsCombo: true,
        showFilterCounts: false,
      },
      table: {
        busy: true,
        items: [],
        initialItems: [],
        fields: [
          {
            key: 'student.name',
            label: 'Student',
            sortable: false,
            filterable: true,
            visible: true,
            tdClass: 'align-middle',
            thClass: 'border-0',
            class: 'col-student'
          },
          {
            key: 'festival',
            label: 'Festival',
            sortable: false,
            filterable: true,
            visible: false,
            tdClass: 'align-middle',
            thClass: 'border-0',
            class: 'col-ranking-local',
            //formatter: this.getFestivalName
          },
          {
            key: 'instrument',
            label: 'Instrument',
            sortable: false,
            filterable: true,
            visible: false,
            tdClass: 'align-middle',
            thClass: 'border-0',
            class: 'col-ranking-local',
            //formatter: this.getInstrumentName
          },
          {
            key: 'createdAt',
            label: 'Created',
            sortable: false,
            filterable: false,
            visible: false,
            tdClass: 'align-middle',
            thClass: 'border-0',
          },
          {
            key: 'updatedAt',
            label: 'Last Modified',
            sortable: false,
            filterable: false,
            visible: false,
            tdClass: 'align-middle',
            thClass: 'border-0',
          },
          {
            key: 'ranking.local',
            label: 'Local Rank',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle',
            thClass: 'border-0',
            class: 'col-ranking-local'
          },
          {
            key: 'ranking.score',
            label: 'Score',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle',
            thClass: 'border-0',
            class: 'col-ranking-score'
          },
          {
            key: 'row-options',
            label: '',
            sortable: false,
            filterable: false,
            visible: true,
            tdClass: 'align-middle',
            thClass: 'border-0',
            class: 'col-row-action'
          },
        ],
        filter: {
          festival: null,
          form: null,
          instrument: null,
          school: null,
          student: null
        },
        paging: {
          page: 1,
          size: 25,
          total: 0,
          options: {
            sizes: [10, 25, 50, 100]
          }
        },
        search: '',
        sorting: {
          by: 'student.name.last',
          desc: false,
          fields: [
            { key: 'student.name.first', text: 'First Name'},
            { key: 'student.name.last', text: 'Last Name'},
            { key: 'ranking.local', text: 'Local Rank'},
            { key: 'ranking.score', text: 'Score'},
            { key: 'createdAt', text: 'Submitted'}
          ],
          comparator: null,
          comparatorKey: null,
          comparators: [
            { key: 'lrs', text: 'Local Rank, Score', comparator: (a, b, key, sortDesc) => this.compareByLocalRankScore(a, b, key, sortDesc) },
            { key: 'lnlrs', text: 'Last Name, Local Rank, Score', comparator: (a, b, key, sortDesc ) => this.compareByLastNameLocalRankScore(a, b, key, sortDesc ) },
            { key: 'filrs', text: 'Festival, Instrument, Local Rank, Score', comparator: (a, b, key, sortDesc ) => this.compareByFestivalInstrumentLocalRankScore(a, b, key, sortDesc ) },
          ]
        },
        subscriptions: {
          onCreate: null,
          onUpdate: null,
          onDelete: null
        },
      },
      ui: {
        layout: {
          showLeftColumn: true,
        },
      },
      options: {
        scores: [100, 99, 98, 97, 96, 95, 94, 93, 92, 91, 90],
        festivals: {
          loading: true,
          items: []
        },
        forms: {
          loading: true,
          items: []
        },
        instruments: {
          loading: true,
          items: []
        },
        schools: {
          loading: false,
          items: []
        },
        applications: {
          loading: true,
          loaded: 0
        },
      },
      rules: {
        local: { required: true, numeric: true, min_value: 0, max_value: 100 },
        score: { required: true, numeric: true, min_value: 0, max_value: 100 }
      },
      icon: 'fas fa-clipboard',
      save: {
        complete: false,
        processing: false,
        status: '',
        changes: [],
        computedChanges: [],
        errors: [],
        progress: 0,
        details: {
          changes: false,
          processed: true
        },
      }
    }
  },
  computed: {
    pagination() {
      let to = this.table.paging.size * (this.table.paging.page)
      if(this.table.paging.size === 0 || to >= this.table.paging.total) {
        to = this.table.paging.total
      }
      let from = 0
      if(this.table.paging.total > 0) {
        from = this.table.paging.size * (this.table.paging.page - 1) + 1
      }

      return {
        from: from,
        to: to,
        of: this.table.paging.total,
      }
    },
    visibleFields() {
      return this.table.fields.filter(field => field.visible)
    },
    filteredApplications() {
      if(this.table.busy || this.table.items.length === 0) {
        return []
      }

      //filter: if hide scored setting is enabled, and the rankings have values greater than 0 then return false, otherwise true
      const applications = this.table.items.filter(item => !(this.settings.hideScored && item.ranking.score > 0 && item.ranking.local > 0))
      const fuse = new Fuse(applications, {
        useExtendedSearch: true,
        threshold: 0.2,
        keys: [
          'id',
          'applicationFestivalId',
          'applicationFormId',
          'applicationInstrumentId',
          'student.id',
          'student.name.full',
        ]
      })

      const query = { $and: [ ] }
      if(this.table.filter.form) { query.$and.push({ applicationFormId: `'${this.table.filter.form}` }) }
      if(this.table.filter.festival) { query.$and.push({ applicationFestivalId: `'${this.table.filter.festival}` }) }
      if(this.table.filter.instrument) { query.$and.push({ applicationInstrumentId: `'${this.table.filter.instrument}` }) }
      if(this.table.filter.student) { query.$and.push({ 'student.id': `'${this.table.filter.student}` }) }
      if(this.table.search) {
        query.$and.push({
          $or: [
            { id: `'${this.table.search}` },
            { 'student.id': `'${this.table.search}`},
            { 'student.name.full': `'${this.table.search}`}
          ]
        })
      }
      let items;
      if(query.$and.length) {
        items = fuse.search(query).map(({ item }) => item)/*.sort(this.compareApplications())*/
      }
      else {
        items = applications/*.sort(this.compareApplications())*/
      }

        let localTabIndex = 1;
        let scoreTabIndex = 2;
        let sort;
        if(this.table.sorting.comparator) {
            sort = this.table.sorting.comparator
        }
        else {
            sort = this.compareApplications()
        }

        items = items.sort(sort).map((item, index) => {
            const newItem = {
                ...item,
                tabIndex: {
                    local: localTabIndex,
                    score: scoreTabIndex
                },
                state: {
                    visible: !(this.settings.hideScored && item.ranking.score > 0 && item.ranking.local > 0)
                }
            };

            // Increment tab indices for next row
            localTabIndex += 2;
            scoreTabIndex += 2;
            return newItem;
        });

      this.table.paging.total = items.length // eslint-disable-line vue/no-side-effects-in-computed-properties
      return items
    },
    updatableValues() {
      const values = []
      this.table.initialItems.forEach(item => {
        const changes = this.save.changes.filter(change => change.id === item.id)
        if(changes.length) {
          const input = {
            id: item.id,
            ranking: { local: null, score: null},
            changes: [],
          }

          const localChange = this.save.changes.find(change => change.id === item.id && change.key === 'local')
          if(localChange) {
            input.ranking.local = localChange.value
            input.changes.push({ type: 'local rank', from: item.ranking.local, to: localChange.value})
          }
          else { input.ranking.local = item.ranking.local }

          const scoreChange = this.save.changes.find(change => change.id === item.id && change.key === 'score')
          if(scoreChange) {
            input.ranking.score = scoreChange.value
            input.changes.push({ type: 'score', from: item.ranking.score, to: scoreChange.value})
          }
          else { input.ranking.score = item.ranking.score }

          /** Determine Date **/
          input.date = new Date(Math.max(...changes.map(change => new Date(change.date)))).toISOString();

          values.push(input)
        }
      })
      return values.sort((a, b) => b.date.localeCompare(a.date))
    },
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.$store.dispatch('storageLocal/updateItem', {
          key: 'all-state-scores',
          subKey: 'filter',
          value: {
            festival: this.table.filter.festival,
            form: this.table.filter.form,
            instrument: this.table.filter.instrument
          }
        })
      }
    },
  },
  async mounted() {
    this.loadLocalSettings();
    this.loadQueryValues()

    this.$refs.layout.state.loading = false
    await this.getCurrentUser()
    await Promise.all([
      this.listFestivals(),
      this.listForms(),
      this.listInstruments()
    ]);

    await this.getApplications()
  },
  beforeDestroy() {
    if(this.table.subscriptions?.onCreate) {
      this.table.subscriptions.onCreate.unsubscribe()
    }
    if(this.table.subscriptions?.onUpdate) {
      this.table.subscriptions.onUpdate.unsubscribe()
    }
    if(this.table.subscriptions?.onDelete) {
      this.table.subscriptions.onDelete.unsubscribe()
    }
  },
  methods: {
    loadLocalSettings() {
      if(this.$store.getters['storageLocal/isRouteLocalEnabled']('all-state-scores')) {
        const item = this.$store.getters['storageLocal/getItem']('all-state-scores')
        if(item) {
          if(item?.filter) {
            if(item.filter?.festival !== null) { this.table.filter.festival = item.filter.festival }
            if(item.filter?.form !== null) { this.table.filter.form = item.filter.form }
            if(item.filter?.instrument !== null) { this.table.filter.instrument = item.filter.instrument }
          }

          if(item?.paging) {
            if(item.paging?.page !== null) { this.table.paging.page = item.paging.page }
            if(item.paging?.size !== null) { this.table.paging.size = item.paging.size }
          }

          if(item?.sorting) {
            if(item.sorting?.by !== null) { this.table.sorting.by = item.sorting.by }
            if(item.sorting?.desc !== null) { this.table.sorting.desc = item.sorting.desc }
          }

          if(item?.settings) {
            if(item.settings?.scoreAsCombo !== null) { this.settings.scoreAsCombo = item.settings.scoreAsCombo }
            if(item.settings?.showFilterCounts !== null) { this.settings.showFilterCounts = item.settings.showFilterCounts }
            if(item.settings?.hideScored !== null) {
              this.settings.hideScored = item.settings.hideScored
              this.toggleHideScored(this.settings.hideScored)
            }
          }
        }
      }
    },
    loadQueryValues() {
      if(this.$route.query.filters) {
        this.table.filter = JSON.parse(atob(this.$route.query.filters))
      }
    },
    async refresh() {
      this.save.changes = []
      this.save.computedChanges = []
      this.save.processing = false
      this.save.complete = false

      this.table.paging.total = 0
      this.table.busy = true

      this.options.festivals.loading = true


      await Promise.all([
        this.listFestivals(),
        this.listApplications()
      ]);
      this.table.busy = false
    },
    async getCurrentUser() {
        /** Get Current User from Store **/
        const cognitoUser = await Auth.currentAuthenticatedUser()

        /** Get User from AppSync **/
        const response = await API.graphql(graphqlOperation(getUser, { id: cognitoUser.attributes['custom:user_id'] }));
        this.user = response.data.getUser
        this.user.groups = cognitoUser.signInUserSession.accessToken.payload['cognito:groups']
    },
    async listForms() {
      const response = await API.graphql(graphqlOperation(listForms));
      this.options.forms.items = response.data.listForms.items.sort((a, b) => a.name.localeCompare(b.name));
      this.options.forms.loading = false
    },
    async listFestivals(nextToken, pagedFestivals) {
      this.options.festivals.loading = true
      const festivals = pagedFestivals || []

      const input = {
        limit: 100,
        nextToken: nextToken,
        filter: {
          createdAt: {
            between: [
                this.settingsStore.app.current.year.start,
              this.settingsStore.app.current.year.end
            ]
          }
        },
      }

      const response = await API.graphql(graphqlOperation(listFestivals, input));
      festivals.push(...response.data.listFestivals.items);

      if(response.data.listFestivals.nextToken) {
        await this.listFestivals(response.data.listFestivals.nextToken, festivals)
      }
      else {
        this.options.festivals.items = festivals.sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }))
        if(!this.options.festivals.items.some(item => item.id === this.table.filter.festival)) {
          this.table.filter.festival = null
        }
        this.options.festivals.loading = false
        this.options.festivals.loaded = true
      }
    },
    async listInstruments() {
      const response = await API.graphql(graphqlOperation(listInstruments));
      this.options.instruments.items = response.data.listInstruments.items.sort((a, b) => a.name.localeCompare(b.name));
      this.options.instruments.loading = false
    },
    async listSchools(nextToken, pagedSchools) {
      this.options.schools.loading = true
      const schools = pagedSchools || []
      const response = await API.graphql(graphqlOperation(listSchools, { limit: 500, nextToken: nextToken }));
      schools.push(...response.data.listSchools.items);

      if(response.data.listSchools.nextToken) {
        await this.listSchools(response.data.listSchools.nextToken, schools)
      }
      else {
        this.options.schools.items = schools
        this.options.schools.loading = false
        this.options.schools.loaded = true
      }
    },

    async getApplications() {
      this.table.busy = true
      if(this.$store.getters['storageLocal/isRouteDisabled']('all-state-scores') || this.$store.getters['all-state-scores/isExpired']) {
        await this.listApplications();
      }
      else {
        await this.listApplicationsFromStore()
      }
      this.table.busy = false
    },
    async listApplicationsFromStore() {
      this.table.items = this.$store.getters['all-state-scores/getItems']
      this.table.initialItems = JSON.parse(JSON.stringify(this.table.items.map(item => ({ id: item.id, ranking: item.ranking }) ))) //json parse/stringify created a deep copy
      if(!this.table.items.length) {
        await this.listApplications();
      }
    },
    async listApplications(nextToken, pagedApplications) {
      const applications = pagedApplications || []
      const input = {
        limit: 500,
        nextToken: nextToken,
        filter: {
          createdAt: {
            between: [
              this.settingsStore.app.current.year.start,
              this.settingsStore.app.current.year.end
            ]
          }
        },
      }
      const response = await API.graphql(graphqlOperation(listApplications, input));
      applications.push(...response.data.listApplications.items)
      applications.forEach(application => {
        if (application.student && application.student.name) {
            application.student.name.first = application.student.name.first ? application.student.name.first.trim() : '';
            application.student.name.last = application.student.name.last ? application.student.name.last.trim() : '';
            application.student.name.full = `${application.student.name.first} ${application.student.name.last}`;
        }
        application.hasDetails = false
        application.editable = false
      })

      this.table.paging.total = applications.length
      if(response.data.listApplications.nextToken) {
        await this.listApplications(response.data.listApplications.nextToken, applications)
      }
      else {
        this.table.items = applications/*.sort(this.compareApplications())*/
        this.table.initialItems = JSON.parse(JSON.stringify(this.table.items.map(item => ({ id: item.id, ranking: item.ranking }) ))) //json parse/stringify created a deep copy
        await this.$store.dispatch('all-state-scores/setItems', {
          key: 'all-state-scores',
          items: this.table.items
        })
      }
    },
    async getApplicationDetails(item) {
        if(item.hasDetails === false) {
            const response = await API.graphql(graphqlOperation(getApplicationDetails, { id: item.id } ));
            const application = response.data.getApplication
            if(application) {
                const updatedItem = {...item};
                updatedItem.student.dob = application?.student?.dob
                updatedItem.student.grade = application?.student?.grade
                updatedItem.student.address = application?.student?.address
                updatedItem.student.email = application.student?.email
                updatedItem.form = application?.form
                updatedItem.festival = application?.festival
                updatedItem.instrument = application?.instrument
                updatedItem.questions = application?.questions
                updatedItem.grading = application?.grading
                updatedItem.recommendation = application?.recommendation
                updatedItem.comments = application?.comments
                updatedItem.hasDetails = true

                // Find the index of the item in the array
                const index = this.table.items.findIndex(i => i.id === item.id);

                // Replace the old item with the updated item using Vue.set to ensure reactivity
                if (index !== -1) {
                    this.$set(this.table.items, index, updatedItem);
                }
            }
            else {
                item.hasDetails = true
            }
        }
    },
    async updateApplicationDetails(item) {
        // Make a copy of item and update the properties on the copy
        const updatedItem = {...item};
        updatedItem.applicationFestivalId = item.festival.id;
        updatedItem.applicationFormId = item.form.id;
        updatedItem.applicationInstrumentId = item.instrument.id;
        updatedItem.editable = false;

        const input = {
            id: item.id,
            applicationFestivalId: updatedItem.festival.id,
            applicationFormId: updatedItem.form.id,
            applicationInstrumentId: updatedItem.instrument.id,
            questions: updatedItem.questions,
            grading: updatedItem.grading,
            recommendation: updatedItem.recommendation,
            comments: updatedItem.comments
        }
        try {
            await API.graphql(graphqlOperation(updateApplication, { input: input }));

            // Find the index of the item in the array
            const index = this.table.items.findIndex(i => i.id === item.id);

            // Replace the old item with the updated item using Vue.set to ensure reactivity
            if (index !== -1) {
                this.$set(this.table.items, index, updatedItem);
            }
        }
        catch(error) {
            console.error(error)
        }
    },

    onCreateApplication() {
      this.table.subscriptions.onCreate = API.graphql(graphqlOperation(onCreateApplication)).subscribe(async (sourceData) => {
        this.table.busy = true
        const createdApp = sourceData.value.data.onCreateApplication
        if (createdApp) {
          const response = await API.graphql(graphqlOperation(getApplication, {id: createdApp.id}));
          const application = response.data.getApplication
          application.student.name.full = `${application.student.name.first} ${application.student.name.last}`

          this.table.paging.total += 1
          this.table.items = [application, ...this.table.items];
        }
        this.table.busy = false
      });
    },
    onUpdateApplication() {
      this.table.subscriptions.onUpdate = API.graphql(graphqlOperation(onUpdateApplication)).subscribe((sourceData) => {
        const updatedApplication = sourceData.value.data.onUpdateApplication // { id, ranking.local, ranking.score }
        if (updatedApplication && this.table.items.map(item => item.id).includes(updatedApplication.id)) {
          const application = this.table.items.find(app => app.id === updatedApplication.id)
          if(application.ranking.local !== updatedApplication.ranking.local || application.ranking.score !== updatedApplication.ranking.score) {
            this.syncNotification()
            application.ranking.local = updatedApplication.ranking.local
            application.ranking.score = updatedApplication.ranking.score
            this.table.initialItems = JSON.parse(JSON.stringify(this.table.items.map(item => ({ id: item.id, ranking: item.ranking }) )))
          }
        }
      });
    },

    getComputedChanges() {
      const values = []
      this.table.initialItems.forEach(item => {
        const changes = this.save.changes.filter(change => change.id === item.id)
        if(changes.length) {
          const input = {
            id: item.id,
            ranking: { local: null, score: null},
            saving: false,
            success: null,
            error: null,
            changes: [],
            icon: 'circle',
            variant: 'primary'
          }

          const localChange = this.save.changes.find(change => change.id === item.id && change.key === 'local')
          if(localChange) {
            input.ranking.local = localChange.value
            input.changes.push({ type: 'local rank', from: item.ranking.local, to: localChange.value})
          }
          else { input.ranking.local = item.ranking.local }

          const scoreChange = this.save.changes.find(change => change.id === item.id && change.key === 'score')
          if(scoreChange) {
            input.ranking.score = scoreChange.value
            input.changes.push({ type: 'score', from: item.ranking.score, to: scoreChange.value})
          }
          else { input.ranking.score = item.ranking.score }

          values.push(input)
        }
      })
      return values
    },

    async saveChanges(state) {
      this.save.complete = false
      this.save.processing = true

      this.save.status = 'Computing Changes'
      this.save.computedChanges = this.getComputedChanges()

      this.save.status = 'Saving Changes'
      await this.save.computedChanges.reduce(async (referencePoint, change, index) => {
        try {
          change.saving = true
          await referencePoint;
          this.save.progress = index + 1
          await API.graphql(graphqlOperation(updateApplication, { input: { id: change.id, ranking: change.ranking} } ));
          change.success = true
          change.variant = 'success'
          change.icon = 'check-circle-fill'
          change.updatedAt = new Date().toISOString()
        }
        catch (e121) {
          change.success = false
          change.variant = 'danger'
          change.icon = 'x-circle-fill'
          change.error = e121
          this.save.errors.push({ application: change.id, error: e121})
        }
        finally {
          change.saving = false
        }
      }, Promise.resolve());


      // Update Initial Items, as well as revert back bad values in the table to their initial value
      this.save.status = 'Updating Initial Items'
      this.save.computedChanges.forEach(change => {
        const initialItem = this.table.initialItems.find(item => item.id === change.id)
        if(initialItem) { initialItem.ranking = change.ranking }

        const tableItem = this.table.items.find(item => item.id === change.id)
        if(tableItem) {
          tableItem.ranking = JSON.parse(JSON.stringify(change.ranking))
          tableItem.updatedAt = change?.updatedAt ? change?.updatedAt : tableItem.updatedAt
        }
      })

      if(this.$store.getters['storageLocal/isRouteCacheEnabled']('all-state-scores')) {
        this.$store.dispatch('all-state-scores/setItems', {
          key: 'all-state-scores',
          items: this.table.items
        })
      }

      this.save.processing = false
      this.save.changes = []
      this.save.complete = true
    },

    onChange(key, value, rules, application) {
      validate(value, rules).then(result => {
        if (result.valid) {
          const initialItem = this.table.initialItems.find(item => item.id === application.id)
          if(Number.parseInt(initialItem?.ranking[key], 10) === Number.parseInt(value, 10)) {
            this.save.changes = this.save.changes.filter(change => !(change.id === application.id && change.key === key))
            this.$refs[`${application.id}-${key}-provider`].reset()
          }
          else {
            const changeValue = { id: application.id, key: key, value: Number.parseInt(value, 10), date: new Date().toISOString() }
            const index = this.save.changes.findIndex(change => change.id === application.id && change.key === key)
            if(index >= 0) {
              this.save.changes.splice(index, 1, changeValue)
            }
            else {
              this.save.changes.push(changeValue)
            }
          }
        }
        else {
          this.save.changes = this.save.changes.filter(change => !(change.id === application.id && change.key === key))
        }
      })
    },
    onBlur(key, item) {
      const initialItem = this.table.initialItems.find(ii => ii.id === item.id)
      if(initialItem && (Number.parseInt(initialItem?.ranking[key], 10) === Number.parseInt(item?.ranking[key], 10))) {
        this.$refs[`${item.id}-${key}-provider`].reset()
      }
    },
    onFocus(event) {
       /* const input = event.target;
        const len = input.value.length;
        input.focus();
        if (input.setSelectionRange) {
            input.setSelectionRange(len, len);
        }
        else if (input.createTextRange) {
            const range = input.createTextRange();
            range.collapse(true);
            range.moveEnd('character', len);
            range.moveStart('character', len);
            range.select();
        }*/
    },

    /** Save Util **/
    getInputGroupClass(validationContext) {
      const state = this.getValidationState(validationContext)
      if(state === null) return ''
      return state ? 'is-valid' : 'is-invalid'
    },
    undoToInitialItem(key, item) {
      const initialItem = this.table.initialItems.find(initial => initial.id === item.id)
      if(initialItem) {
        item.ranking[key] = initialItem.ranking[key]
        this.save.changes = this.save.changes.filter(change => !(change.id === item.id && change.key === key))
        this.$refs[`${item.id}-${key}-provider`].reset()
      }
    },
    formatInput(value) {
      if (value < 0) { return 0 }
      return value
    },
    getSaveIcon(save) {
      if(save.complete) {
        if(save.computedChanges.every(item => item.success)) return 'check-circle-fill'
        if(save.computedChanges.every(item => item.error)) return 'x-circle-fill'
        return 'slash-circle-fill'
      }
      return 'circle'
    },
    getSaveVariant(save) {
      if(save.complete) {
        if(save.computedChanges.every(item => item.success)) return 'success'
        if(save.computedChanges.every(item => item.error)) return 'danger'
        return 'warning'
      }
      return 'primary'
    },
    getSaveClass() {
      if(this.save.processing || this.save.complete) {
        if(this.$refs['processed-scrollbar']?.ps?.scrollbarYActive === true) {
          return 'mr-2'
        }
      }
      return ''
    },

    /** Filters **/
    filterFestivalCount(id) {
      return this.table.items.filter(app => app.applicationFestivalId === id)
          .filter(app => (this.table.filter.form ? app.applicationFormId === this.table.filter.form : true))
          .filter(app => (this.table.filter.instrument ? app.applicationInstrumentId === this.table.filter.instrument : true)).length
    },
    filterFormCount(id) {
      return this.table.items.filter(app => app.applicationFormId === id)
          .filter(app => (this.table.filter.festival ? app.applicationFestivalId === this.table.filter.festival : true))
          .filter(app => (this.table.filter.instrument ? app.applicationInstrumentId === this.table.filter.instrument : true)).length
    },
    filterInstrumentCount(id) {
      return this.table.items.filter(app => app.applicationInstrumentId === id)
          .filter(app => (this.table.filter.festival ? app.applicationFestivalId === this.table.filter.festival : true))
          .filter(app => (this.table.filter.form ? app.applicationFormId === this.table.filter.form : true)).length
    },

    /** Searching **/
    filterFestival(options, search) {
      const fuse = new Fuse(options, {
        keys: ['name', 'site'],
        threshold: 0.3,
        shouldSort: true
      })
      return search.length ? fuse.search(search).map(({ item }) => item) : fuse.list
    },
    filterSchool(options, search) {
      const fuse = new Fuse(options, {
        keys: ['name.legal', 'name.popular'],
        shouldSort: true,
      })
      return search.length ? fuse.search(search).map(({ item }) => item) : fuse.list
    },
    async onSchoolsOpen() {
      if (this.options.schools.items.length === 0) {
        this.options.schools.loading = true
        await this.listSchools()
      }
      this.options.schools.loading = false
      return this.options.schools.items
    },

    /** Validation **/
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    getValidationClass(validationContext) {
      const state = this.getValidationState(validationContext)
      if(state === null) return null
      return state ? 'is-valid' : 'is-invalid'
    },
    canUpdate(ref) {
      return new Promise((resolve, reject) => {
        this.$refs[ref].validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

    getFestival(id) {
      const festival = this.options.festivals.items.find(item => item.id === id)
      if(festival) return festival
      return { name: 'Unknown', site: 'Unknown'}
    },
    getFestivalName(id) {
      const festival = this.options.festivals.items.find(item => item.id === id)
      if(festival) return festival.name
      return 'Unknown Festival'
    },
    getFestivalText(id) {
      const festival = this.options.festivals.items.find(item => item.id === id)
      if(festival) return `${festival.name} - ${festival.site}`
      return 'Unknown Festival'
    },
    getInstrumentName(id) {
      const instrument = this.options.instruments.items.find(item => item.id === id)
      if(instrument) return instrument.name
      return 'Unknown Instrument'
    },
    gradeVariant(part) {
      if(part === null) {
        return 'danger'
      }
      return 'primary'
    },

    /** Settings **/
    toggleHideScored(isChecked) {
      //this.table.items.forEach(item => { if(item.ranking.local > 0 && item.ranking.score > 0) { item.state.visible = !isChecked } })
      this.$store.dispatch('storageLocal/updateItem', {
        key: 'all-state-scores',
        subKey: 'settings.hideScored',
        value: isChecked
      })
    },
    onShowFilterCounts(isChecked) {
      this.settings.showFilterCounts = isChecked
      this.$store.dispatch('storageLocal/updateItem', {
        key: 'all-state-scores',
        subKey: 'settings.showFilterCounts',
        value: isChecked
      })
    },
    onScoreAsCombo(isChecked) {
      this.settings.scoreAsCombo = isChecked
      this.$store.dispatch('storageLocal/updateItem', {
        key: 'all-state-scores',
        subKey: 'settings.scoreAsCombo',
        value: isChecked
      })
    },

    /** Paging **/
    onPageChange(page) {
      this.table.paging.page = page
      this.$store.dispatch('storageLocal/updateItem', {
        key: 'all-state-scores',
        subKey: 'paging.page',
        value: this.table.paging.page
      })
    },
    onPageSize(size) {
      this.table.paging.size = size
      this.$store.dispatch('storageLocal/updateItem', {
        key: 'all-state-scores',
        subKey: 'paging.size',
        value: this.table.paging.size
      })
    },

    /** Sorting **/
    onSort(ctx) {
      this.table.sorting.by = ctx.sortBy
      this.table.sorting.desc = ctx.sortDesc
      /*this.$store.dispatch('storageLocal/updateItem', {
        key: this.$route.name,
        subKey: 'sorting',
        value: this.table.sorting
      })*/
    },
    onSortOrder() {
      this.table.sorting.desc = !this.table.sorting.desc
      this.$store.dispatch('storageLocal/updateItem', {
        key: 'all-state-scores',
        subKey: 'sorting',
        value: {
          by: this.table.sorting.by,
          desc: this.table.sorting.desc
        }
      })
    },
    onSortBy(by) {
      this.table.sorting.by = by
      this.table.sorting.comparator = null
      this.$store.dispatch('storageLocal/updateItem', {
        key: 'all-state-scores',
        subKey: 'sorting.by',
        value: this.table.sorting.by
      })
    },
    onSortCompare(item) {
      this.table.sorting.by = item.key
      this.table.sorting.comparator = item.comparator
    },
    compareByLastNameLocalRankScore(a, b, key, sortDesc) {
      return a.student?.name?.last?.localeCompare(b.student?.name?.last)
          || a.student?.name?.first?.localeCompare(b.student?.name?.first)
          || a.ranking?.local - b.ranking?.local
          || b.ranking?.score - a.ranking?.score
    },
    compareByFestivalInstrumentLocalRankScore(a, b, key, sortDesc) {
      return this.getFestivalText(a.applicationFestivalId).localeCompare( this.getFestivalText(b.applicationFestivalId), 'en', { numeric: true })
          || this.getInstrumentName(a.applicationInstrumentId).localeCompare(this.getInstrumentName(b.applicationInstrumentId), 'en', { numeric: true })
          || a.ranking?.local - b.ranking?.local
          || b.ranking?.score - a.ranking?.score
    },
    compareByLocalRankScore(a, b, key, sortDesc) {
      return a.ranking.local - b.ranking.local
          || b.ranking?.score - a.ranking?.score
    },

    compareApplications1() {
      return (a, b) => (this.table.sorting.desc
              ? _.toString(_.get(b, this.table.sorting.by)).localeCompare(_.toString(_.get(a, this.table.sorting.by)))
              : _.toString(_.get(a, this.table.sorting.by)).localeCompare(_.toString(_.get(b, this.table.sorting.by)))
      )
    },

    compareApplications() {
      return (aRow, bRow) => {
        const aValue = _.toString(_.get(aRow, this.table.sorting.by));
        const bValue = _.toString(_.get(bRow, this.table.sorting.by));

        if (this.table.sorting.desc) {
          return this.table.sorting.comparator
              ? this.table.sorting.comparator(bRow, aRow)
              : bValue.localeCompare(aValue);
        }

        return this.table.sorting.comparator
            ? this.table.sorting.comparator(aRow, bRow)
            : aValue.localeCompare(bValue);
      };
    },
  },
}
</script>

<style lang="scss">
.dropdown-menu-lg {
  min-width: 24rem;
}
.ps-changes {
  max-height: 300px;
}
.ps--active-y.ps-changes {
  padding-right: 1.5rem
}
.ps-processed {
  max-height: 50vh;
}
.ps--active-y.ps-processed {
  padding-right: 1.5rem
}
.alert .list-group .list-group-item:hover {
  background-color: #fff;
}

.table-row-action {
  width: 33px;
  text-align: center;
  padding-left: 1.5rem!important;
  padding-right: 1.5rem!important;
}

.form-group.is-invalid .v-select .vs__dropdown-toggle {
  border-color: #ea5455;
}

.form-group.is-valid .v-select .vs__dropdown-toggle {
  border-color: #28c76f;
}

.btn-transparent.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}
</style>

<style lang="scss">
@media print {
  #table-filters,
  #table-header,
  #table-footer,
  .table-row-options {
    display: none;
  }

  .table.b-table > thead > tr > [aria-sort] {
    background-image: none;
  }

  .form-control {
    border: 0!important;
  }
}
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}

.table.b-table > thead > tr > th {
  background-color: red;
}

.b-table::v-deep {
  th {
    border: 0 !important;
  }
}

#search-apps.form-control-md {
  padding: 1.385rem 1.5rem
}

#applications-list .ps {
  min-height: 50vh;
  height: 50vh;
}
.prepend-group-text {
  min-width: 7.75rem;
}
</style>

<style lang="scss" scoped>
::v-deep {
  .col-student {
    width: auto;
    vertical-align: middle !important;
  }
  .col-ranking-local {
    width: 15%;
    vertical-align: middle !important;
  }
  .col-ranking-score {
    width: 20%;
    vertical-align: middle !important;
  }
  .col-row-action {
    width: 20px;
    vertical-align: middle !important;
    padding: 0.5rem!important;
  }

  @media (max-width: 1439px) {
    .media-aside {
      margin-right:0!important;
      display: none!important;
    }
    .b-avatar {
      display: none!important;
    }
    .student-school {
      display: none!important;
    }
    .festival-prefix {
      display: none!important;
    }
  }


  @media print {
    .card .card-header {
      padding-top: 0!important;
    }
    .form-control {
      border: 0!important;
    }
    .vs__dropdown-toggle {
      border: 0!important;
    }
    .vs__actions {
      display: none!important;
    }
    .media-aside {
      margin-right: 0;
    }
    .b-avatar {
      display: none;
    }
    .col-row-action {
      display: none;
    }
  }
}

.v-select::v-deep {
  .v-select .dropdown-menu {
    width: auto;
    max-width: 100%; /* Change this to the maximum width you want */
  }
  /*.vs__selected-options {
    flex-wrap: nowrap;
    max-width: calc(100% - 40px); !* change this to `- 40px` if you're supporting a `clearable` field; I was not *!
  }
  .vs__selected {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
  }*/
}
</style>

