import Vue from 'vue'
import cache from '@/store/storage.cache.module';
import createPersistedState from 'vuex-persistedstate'

export default {
    created() {
        /*this.$store.registerModule(this.$route.name, cache)
        const state = createPersistedState({
            key: `nyssma.cache.${this.$route.name}`,
            storage: window.sessionStorage,
            paths: [this.$route.name],
            fetchBeforeUse: true
        })*/
        //Vue.use(state)
    }
}
